/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}


.btn-sm:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
  background-color: #556ee6 !important;
  cursor: pointer;
}

.btn-sm:hover {
  // background-color: #556ee6!important;

}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.name-text {
  text-transform: capitalize;
}

.page-title {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 18px;

}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.page-sub-title {
  font-size: 18px;
}

.header-btn {
  // margin-top: 34px;
  float: right;
}

.fas {
  font-size: 15px;

}

.mdi {
  font-size: 15px;

}
.uploaded-image{
  border-radius: 0%;
  margin-top: auto;
  margin-bottom: auto;
  object-fit: contain;
}
.select-style {
  font-size: 14px;
  line-height: 19px;
}

.image-holder {
  height: 90px;
  width: 200px;
  object-fit: contain;
}

.warning-tag {
  margin-top: 3px;
  font-size: 10px;
  color: red;
}

.bshadow {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(5px 5px 5px #222222);
  opacity: .8;
  margin-bottom: 10px;
}

